export const login = async (username, password) => {
  const response = await fetch(`/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
    credentials: 'include', // This is important for including cookies
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  const data = await response.json();
  return {
    ...data,
    roles: data.roles || []
  };
};


export const register = async (username, password, captchaValue) => {
  const body = { username, password };
  
  if (process.env.NODE_ENV === 'production') {
    body.captchaValue = captchaValue;
  }

  const response = await fetch(`/api/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include', // This is important for including cookies
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Registration failed');
  }

  return await response.json();
};

export const checkSession = async () => {
  try {
    const response = await fetch(`/api/auth/check-session`, {
      method: 'GET',
      credentials: 'include', // This is important for including cookies
    });

    if (!response.ok) {
      return { isLoggedIn: false };
    }

    const data = await response.json();
    return { 
      isLoggedIn: data.isLoggedIn, 
      userId: data.userId, 
      roles: data.roles 
    };
  } catch (error) {
    console.error('Error checking session:', error);
    return { isLoggedIn: false };
  }
};

export const logout = async () => {
  try {
    const response = await fetch(`/api/auth/logout`, {
      method: 'POST',
      credentials: 'include', // This is important for including cookies
    });

    if (!response.ok) {
      throw new Error('Logout failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
};
