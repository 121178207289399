import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { checkSession, login as loginService, logout as logoutService } from '../services/auth';
import { validateCredentials } from '../utils/validation';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
    });
    const [userId, setUserId] = useState(() => {
        const storedUserId = localStorage.getItem('userId');
        return (storedUserId && storedUserId !== 'undefined' && storedUserId !== 'null') ? JSON.parse(storedUserId) : null;
    });
    const [userRoles, setUserRoles] = useState(() => {
        const storedUserRoles = localStorage.getItem('userRoles');
        return storedUserRoles ? JSON.parse(storedUserRoles) : [];
    });

    const refreshAuthStatus = useCallback(async () => {
        setIsLoading(true);
        try {
            const { isLoggedIn, userId, roles } = await checkSession();
            setIsLoggedIn(isLoggedIn);
            setUserId(userId);
            setUserRoles(roles || []);
            localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
            localStorage.setItem('userId', JSON.stringify(userId));
            localStorage.setItem('userRoles', JSON.stringify(roles || []));
        } catch (error) {
            setIsLoggedIn(false);
            setUserId(null);
            setUserRoles([]);
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userId');
            localStorage.removeItem('userRoles');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        refreshAuthStatus();
    }, [refreshAuthStatus]);

    const login = async (username, password, rememberMe) => {
        try {
            const response = await loginService(username, password, rememberMe);
            await refreshAuthStatus(); // This will update all states and localStorage
            return response;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await logoutService();
            setIsLoggedIn(false);
            setUserId(null);
            setUserRoles([]);
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userId');
            localStorage.removeItem('userRoles');
        } catch (error) {
            console.error('Logout error:', error);
            throw error;
        }
    };

    const register = async (username, password, captchaValue) => {
        const validationError = validateCredentials(username, password);
        if (validationError) {
            throw new Error(validationError);
        }

        try {
            const response = await fetch(`/api/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, captchaValue }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'A regisztráció sikertelen');
            }

            await refreshAuthStatus();
        } catch (err) {
            console.error('Registration error:', err);
            throw err;
        }
    };

    return (
        <AuthContext.Provider value={{ isLoading, isLoggedIn, userId, userRoles, login, logout, register, refreshAuthStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
