import React, {useState, useEffect, useCallback} from 'react';
import {Box, Heading, Text, VStack, Spinner, useToast, Badge, Button, Image, Stack, HStack, ButtonGroup} from '@chakra-ui/react';
import {getColorSchemeByOccupancy} from '../utils/colorScheme';
import {useAuth} from '../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';

const floorTo15Minutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const floored = Math.floor(minutes / 15) * 15;
    return `${hours.toString().padStart(2, '0')}:${floored.toString().padStart(2, '0')}`;
};

const calculateRemainingTime = (createdAt) => {
    const createdTime = new Date(createdAt).getTime();
    const expirationTime = createdTime + 10 * 60 * 1000; // createdAt + 10 minutes
    const now = new Date().getTime();
    const remainingTime = expirationTime - now;

    if (remainingTime <= 0) {
        return "Lejárt";
    }

    const minutes = Math.floor(remainingTime / 60000);
    const seconds = Math.floor((remainingTime % 60000) / 1000);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const DripReservation = () => {
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeFrames, setTimeFrames] = useState({});
    const [userTimeFrame, setUserTimeFrame] = useState(null);
    const {isLoggedIn} = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const [remainingTimes, setRemainingTimes] = useState({});

    const updateRemainingTimes = useCallback(() => {
        const newRemainingTimes = {};
        reservations.forEach(reservation => {
            if (!reservation.verified) {
                newRemainingTimes[reservation.id] = calculateRemainingTime(reservation.createdAt);
            }
        });
        setRemainingTimes(newRemainingTimes);
    }, [reservations]);

    useEffect(() => {
        const timer = setInterval(() => {
            updateRemainingTimes();
        }, 1000);

        return () => clearInterval(timer);
    }, [updateRemainingTimes]);

    const fetchReservations = async () => {
        try {
            const response = await fetch(`/api/reservations`, {
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch reservations');
            }
            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(`1970-01-01T${a.from}`) - new Date(`1970-01-01T${b.from}`));
            setReservations(sortedData);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to load reservations',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchIndexBoarding = async () => {
        try {
            const response = await fetch('/api/reservations/index_boarding', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch index boarding data');
            }
            const data = await response.json();
            console.log('Index boarding data:', data);
            setTimeFrames(data.timeFrames);
            setUserTimeFrame(data.userTimeFrame);
        } catch (error) {
            console.error('Error fetching index boarding data:', error);
            toast({
                title: 'Error',
                description: 'Failed to load index boarding data',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleTimeFrameSelection = async (timeFrame) => {
        try {
            const response = await fetch('/api/reservations/index_boarding', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({timeFrame}),
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to update time frame');
            }
            const data = await response.json();
            setTimeFrames(data.timeFrames);
            setUserTimeFrame(data.userTimeFrame);
            toast({
                title: 'Success',
                description: data.message,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating time frame:', error);
            toast({
                title: 'Error',
                description: 'Failed to update time frame',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        fetchReservations();
        fetchIndexBoarding();
    }, [isLoggedIn, navigate]);

    const handleVerify = async (reservationId) => {
        try {
            const response = await fetch(`/api/reservations/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({reservationId}),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Hiba a rendelés megerősítése közben');
            }

            toast({
                title: 'Sikeres megerősítés',
                description: 'Rendelés megerősítve',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            fetchReservations();
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDeleteReservation = async (reservationId) => {
        try {
            const response = await fetch(`/api/reservations`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Foglalás törlése sikeres');
            }

            toast({
                title: 'Törölve',
                description: 'A fogalálst töröltük',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            fetchReservations();
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box>
            <Heading as="h2" size="2xl" textAlign="left" marginBottom="6">
                Drip újrázás
            </Heading>
            {loading ? (
                <Spinner/>
            ) : reservations.length === 0 ? (
                <Text>Nincs még foglalás</Text>
            ) : (
                <VStack spacing={4} align="stretch">
                    {reservations.map((reservation) => (
                        <Box
                            key={reservation.token}
                            p={4}
                            borderWidth={1}
                            borderRadius="md"
                            position="relative"
                            backgroundColor={reservation.canceled ? "red.100" : "white"}
                        >
                            <Stack direction={["column", "row"]} spacing={4} align="flex-start">
                                <Image
                                    src={`/api/static/${reservation.Restaurant.imagePath}`}
                                    alt={reservation.Restaurant.name}
                                    boxSize={["100%", "100px"]}
                                    objectFit="cover"
                                    borderRadius="md"
                                />
                                <Box flex={1} width="100%">
                                    {!reservation.verified && (
                                        <Box position={["static", "absolute"]} top={2} right={2} mb={[2, 0]}>
                                            <Button
                                                colorScheme="blue"
                                                size="sm"
                                                onClick={() => handleVerify(reservation.id)}
                                            >
                                                Megerősítés
                                            </Button>
                                        </Box>
                                    )}
                                    <Text fontWeight="bold" marginBottom="6">{reservation.Restaurant.name}</Text>
                                    <Text>A foglálsod <b>{reservation.numberOfPpl}</b> főre/pizzára szól. Foglalás kódja: <b>{reservation.token}</b> </Text>

                                    <HStack spacing={2} display={["none", "flex"]} mt={2} fontSize="sm">
                                        <Text>Mikorra várható az érkezésed?</Text>
                                        <Text color="green.500" >Kevesen jelölték</Text>
                                        <Text color="orange.500">Közepes telítet</Text>
                                        <Text color="red.500" >Sokan jelölték</Text>
                                    </HStack>
                                    <VStack align="left" spacing={1} display={["flex", "none"]} fontSize="sm">
                                        <Text>Mikorra várható az érkezésed?</Text>
                                        <Text color="green.500">Kevesen jelölték</Text>
                                        <Text color="orange.500">Közepes telítet</Text>
                                        <Text color="red.500">Sokan jelölték</Text>
                                    </VStack>
                                    <Box
                                        width="100%"
                                        display="flex"
                                        flexWrap="wrap"
                                        justifyContent="flex-start"
                                        mt={2}
                                    >
                                        {Object.entries(timeFrames).map(([key, value]) => (
                                            <Button
                                                key={key}
                                                size="sm"
                                                onClick={() => handleTimeFrameSelection(key)}
                                                colorScheme={getColorSchemeByOccupancy(value.occupancy)}
                                                variant={key === userTimeFrame ? "solid" : "outline"}
                                                mb={2}
                                                mr={2}
                                                flexShrink={0}
                                            >
                                                {key}
                                            </Button>
                                        ))}
                                    </Box>
                                </Box>
                            </Stack>
                        </Box>
                    ))}
                </VStack>
            )}
        </Box>
    );
};

export default DripReservation;
