import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Box, Heading, Text, VStack, Spinner, useToast, Badge, Button, Image, Stack, Table, Thead, Tbody, Tr, Th, Td, Checkbox, Select, ButtonGroup, TableContainer, IconButton} from '@chakra-ui/react';
import {useAuth} from '../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import {TriangleDownIcon, TriangleUpIcon, ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';

const floorTo15Minutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const floored = Math.floor(minutes / 15) * 15;
    return `${hours.toString().padStart(2, '0')}:${floored.toString().padStart(2, '0')}`;
};

const calculateRemainingTime = (createdAt) => {
    const createdTime = new Date(createdAt).getTime();
    const expirationTime = createdTime + 10 * 60 * 1000; // createdAt + 10 minutes
    const now = new Date().getTime();
    const remainingTime = expirationTime - now;

    if (remainingTime <= 0) {
        return "Lejárt";
    }

    const minutes = Math.floor(remainingTime / 60000);
    const seconds = Math.floor((remainingTime % 60000) / 1000);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const MyReservations = () => {
    const [reservations, setReservations] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [loading, setLoading] = useState(true);
    const {userRoles, isLoggedIn} = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState('from');
    const [sortDirection, setSortDirection] = useState('asc');
    const [arrivedFilter, setArrivedFilter] = useState(null);
    const [remainingTimes, setRemainingTimes] = useState({});
    const [expandedHours, setExpandedHours] = useState({});

    const updateRemainingTimes = useCallback(() => {
        const newRemainingTimes = {};
        reservations.forEach(reservation => {
            if (!reservation.verified) {
                newRemainingTimes[reservation.id] = calculateRemainingTime(reservation.createdAt);
            }
        });
        setRemainingTimes(newRemainingTimes);
    }, [reservations]);

    useEffect(() => {
        const timer = setInterval(() => {
            updateRemainingTimes();
        }, 1000);

        return () => clearInterval(timer);
    }, [updateRemainingTimes]);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const filteredAndSortedReservations = useMemo(() => {
        const filtered = reservations.filter(reservation => {
            if (arrivedFilter === null) return true;
            return arrivedFilter === reservation.arrived;
        });

        const sorted = filtered.sort((a, b) => {
            let aValue, bValue;
            switch (sortColumn) {
                case 'from':
                    aValue = a.from;
                    bValue = b.from;
                    break;
                case 'token':
                    aValue = a.token;
                    bValue = b.token;
                    break;
                case 'username':
                    aValue = a.User?.username || '';
                    bValue = b.User?.username || '';
                    break;
                default:
                    return 0;
            }
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });

        const hourlyTotals = {};
        sorted.forEach(reservation => {
            const hour = reservation.from.split(':')[0];
            if (!hourlyTotals[hour]) {
                hourlyTotals[hour] = {total: 0, reservations: []};
            }
            hourlyTotals[hour].total += reservation.numberOfPpl;
            hourlyTotals[hour].reservations.push(reservation);
        });

        const result = [];
        Object.entries(hourlyTotals).forEach(([hour, data]) => {
            result.push({isSummary: true, hour, total: data.total, reservations: data.reservations});
        });

        return result;
    }, [reservations, arrivedFilter, sortColumn, sortDirection]);

    const toggleHourExpansion = (hour) => {
        setExpandedHours(prev => ({
            ...prev,
            [hour]: !prev[hour]
        }));
    };

    const fetchReservations = async () => {
        try {
            let url = `/api/reservations`;
            if (userRoles.includes('page_admin') && selectedRestaurant) {
                url += `?restaurantId=${selectedRestaurant}`;
            }
            const response = await fetch(url, {
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch reservations');
            }
            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(`1970-01-01T${a.from}`) - new Date(`1970-01-01T${b.from}`));
            setReservations(sortedData);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to load reservations',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchRestaurants = async () => {
        try {
            const response = await fetch(`/api/restaurants`, {
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch restaurants');
            }
            const data = await response.json();
            const enabledRestaurants = data.filter(restaurant => restaurant.reservationEnabled);
            setRestaurants(enabledRestaurants);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to load restaurants',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        if (userRoles.includes('page_admin')) {
            fetchRestaurants();
        } else {
            fetchReservations();
        }
    }, [isLoggedIn, navigate, userRoles]);

    useEffect(() => {
        if ((userRoles.includes('page_admin') && selectedRestaurant) || userRoles.includes('restaurant_admin')) {
            fetchReservations();
        }
    }, [selectedRestaurant, userRoles]);

    const handleVerify = async (reservationId) => {
        try {
            const response = await fetch(`/api/reservations/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({reservationId}),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Hiba a rendelés megerősítése közben');
            }

            toast({
                title: 'Sikeres megerősítés',
                description: 'Rendelés megerősítve',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            fetchReservations();
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDeleteReservation = async (reservationId) => {
        try {
            const response = await fetch(`/api/reservations`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Foglalás törlése sikeres');
            }

            toast({
                title: 'Törölve',
                description: 'A fogalálst töröltük',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            fetchReservations();
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleArrivedChange = async (reservationId, arrived) => {
        try {
            const response = await fetch(`/api/reservations/${reservationId}/arrived`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({arrived}),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to update arrival status');
            }

            toast({
                title: 'Status Updated',
                description: `Arrival status ${arrived ? 'confirmed' : 'unconfirmed'}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            fetchReservations();
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const isAdmin = userRoles.includes('page_admin') || userRoles.includes('restaurant_admin');

    return (
        <Box>
            <Heading as="h2" size="2xl" textAlign="left" marginBottom="6">
                {isAdmin ? 'Foglalások' : 'Foglalásom'}
            </Heading>
            {userRoles.includes('page_admin') && (
                <Box mb={4}>
                    <Select
                        placeholder="Válassz éttermet"
                        value={selectedRestaurant}
                        onChange={(e) => setSelectedRestaurant(e.target.value)}
                    >
                        {restaurants.map((restaurant) => (
                            <option key={restaurant.id} value={restaurant.id}>
                                {restaurant.name}
                            </option>
                        ))}
                    </Select>
                </Box>
            )}
            {isAdmin && (
                <Box mb={4}>
                    <Text mb={2}>Megjelent szűrő:</Text>
                    <ButtonGroup isAttached>
                        <Button
                            onClick={() => setArrivedFilter(null)}
                            colorScheme={arrivedFilter === null ? "blue" : "gray"}
                        >
                            Összes
                        </Button>
                        <Button
                            onClick={() => setArrivedFilter(true)}
                            colorScheme={arrivedFilter === true ? "blue" : "gray"}
                        >
                            Megjelent
                        </Button>
                        <Button
                            onClick={() => setArrivedFilter(false)}
                            colorScheme={arrivedFilter === false ? "blue" : "gray"}
                        >
                            Nem jelent meg
                        </Button>
                    </ButtonGroup>
                </Box>
            )}
            {loading ? (
                <Spinner/>
            ) : reservations.length === 0 ? (
                <Text>Nincs még foglalás</Text>
            ) : isAdmin ? (
                <TableContainer overflowX="auto" width="100%">
                    <Table variant="simple" size={["sm", "md"]}>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th cursor="pointer" onClick={() => handleSort('from')}>
                                    Időpont {sortColumn === 'from' && (sortDirection === 'asc' ? <TriangleUpIcon/> : <TriangleDownIcon/>)}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort('token')}>
                                    Kód {sortColumn === 'token' && (sortDirection === 'asc' ? <TriangleUpIcon/> : <TriangleDownIcon/>)}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort('username')}>
                                    Név {sortColumn === 'username' && (sortDirection === 'asc' ? <TriangleUpIcon/> : <TriangleDownIcon/>)}
                                </Th>
                                <Th>Fő</Th>
                                <Th>Megj.</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredAndSortedReservations.map((item) => (
                                <React.Fragment key={`summary-${item.hour}`}>
                                    <Tr backgroundColor="gray.100">
                                        <Td>
                                            <IconButton
                                                aria-label="Expand/Collapse"
                                                icon={expandedHours[item.hour] ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                                                onClick={() => toggleHourExpansion(item.hour)}
                                                size="sm"
                                            />
                                        </Td>
                                        <Td colSpan={3} fontWeight="bold">{`${item.hour}:00 - ${item.hour}:59 Összesítés`}</Td>
                                        <Td fontWeight="bold">{item.total}</Td>
                                        <Td></Td>
                                    </Tr>
                                    {expandedHours[item.hour] && item.reservations.map((reservation) => (
                                        <Tr
                                            key={reservation.token}
                                            backgroundColor={reservation.canceled ? "red.100" : "white"}
                                        >
                                            <Td></Td>
                                            <Td>{floorTo15Minutes(reservation.from)}</Td>
                                            <Td>{reservation.token}</Td>
                                            <Td>{reservation.User?.username}</Td>
                                            <Td>{reservation.numberOfPpl}</Td>
                                            <Td>
                                                <Checkbox
                                                    isChecked={reservation.arrived}
                                                    onChange={(e) => handleArrivedChange(reservation.id, e.target.checked)}
                                                    isDisabled={reservation.canceled}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <VStack spacing={4} align="stretch">
                    {reservations.map((reservation) => (
                        <Box
                            key={reservation.token}
                            p={4}
                            borderWidth={1}
                            borderRadius="md"
                            position="relative"
                            backgroundColor={reservation.canceled ? "red.100" : "white"}
                        >
                            <Stack direction={["column", "row"]} spacing={4} align="flex-start">
                                <Image
                                    src={`/api/static/${reservation.Restaurant.imagePath}`}
                                    alt={reservation.Restaurant.name}
                                    boxSize={["100%", "100px"]}
                                    objectFit="cover"
                                    borderRadius="md"
                                />
                                <Box flex={1} width="100%">
                                    <Box position={["static", "absolute"]} top={2} right={2} mb={[2, 0]}>
                                        <Button
                                            colorScheme="red"
                                            size="sm"
                                            onClick={() => handleDeleteReservation(reservation.id)}
                                            mr={2}
                                        >
                                            Lemondás
                                        </Button>
                                        {!reservation.verified && (
                                            <Button
                                                colorScheme="blue"
                                                size="sm"
                                                onClick={() => handleVerify(reservation.id)}
                                            >
                                                Megerősítés
                                            </Button>
                                        )}
                                    </Box>
                                    <Text fontWeight="bold">{reservation.Restaurant.name}</Text>
                                    {!reservation.Restaurant.canceledReason || !reservation.Restaurant.isInactive ?  (
                                        <>
                                            <Text>Időpont: {floorTo15Minutes(reservation.from)}</Text>
                                            <Text>Fő: {reservation.numberOfPpl}</Text>
                                            {reservation.verified && (
                                                <VStack align="start" spacing={1}>
                                                    <Text>Kód: {reservation.token} (Ezt a kódot kérik majd az étteremben tőled)</Text>
                                                </VStack>
                                            )}
                                            <Text>Státus:
                                                <Badge colorScheme={reservation.verified ? "green" : "red"}>
                                                    {reservation.verified ? "Megerősített" : "Nem megerősített"}
                                                </Badge>
                                            </Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text>{reservation.Restaurant.canceledReason}</Text>
                                            <Text>Sajnos az étterem mégsem vesz részt a rendezvényen.</Text>
                                            <Text>Ha szeretnél máshol pizzázni, töröld ezt a fogalást és válassz egy másik helyet!</Text>
                                        </>
                                    )}
                                    {!reservation.verified && (
                                        <Text>
                                            Hátralévő idő a megerősítéshez: {remainingTimes[reservation.id] || calculateRemainingTime(reservation.createdAt)}
                                        </Text>
                                    )}
                                </Box>
                            </Stack>
                        </Box>
                    ))}
                </VStack>
            )}
        </Box>
    );
};

export default MyReservations;
