import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Image,
    Text,
    Flex,
    Link,
    useBreakpointValue,
} from '@chakra-ui/react';
import {useAuth} from '../contexts/AuthContext'; // Assuming we have this custom hook

const IndexPage = () => {
    const isMobile = useBreakpointValue({base: true, md: false});
    const {isLoggedIn} = useAuth(); // Get login status

    return (
        <Box>
            <Heading as="h2" size="2xl" textAlign="center" marginBottom="6">
                Nápolyi Pizzériák Éjszakája
            </Heading>
            <VStack spacing={10} align="center">
                <Flex
                    padding="0 20px"
                    alignItems="flex-start"
                    direction={isMobile ? "column" : "row"}
                >
                    <Image
                        src="api/static/resources/logobig.png"
                        alt="Pizza Application Logo"
                        maxWidth={isMobile ? "100%" : "200px"}
                        objectFit="contain"
                        marginRight={isMobile ? "0" : "20px"}
                        marginBottom={isMobile ? "20px" : "0"}
                        alignSelf={isMobile ? "center" : "flex-start"}
                    />
                    <VStack spacing={4} align="stretch">
                        {!isLoggedIn ? (
                            <>
                                <Text textAlign="justify" fontSize="2xl">
                                    Üdvözlünk újra! Örülünk, hogy csatlakozol hozzánk a Nápolyi Pizzériák Éjszakáján. 2024 szeptember 19-én, 18:00-23:00 óráig, a kijelölt éttermekben/pizzériákban,
                                    ünnepi, 50%-os áron várunk.
                                </Text>
                                <Text textAlign="justify" fontSize="2xl">
                                    Ne felejtsd el lefoglalni a helyedet! Az előjegyzési rendszerünkkel könnyedén biztosíthatod a helyed kedvenc pizzériádban.
                                </Text>
                                <Text textAlign="justify" fontSize="2xl">
                                    Ígértük, hogy hagyomány lesz, így 2024 szeptember 19-én ismét együtt pizzázunk országszerte. Zalától Baranyáig, 18:00-23:00 óráig, a kijelölt
                                    éttermekben/pizzériákban,
                                    ünnepi, 50%-os áron.
                                </Text>
                                <Text textAlign="justify" fontSize="2xl">
                                    A tavalyi nagy érdeklődésre való tekintettel ezúttal előjegyzési rendszerrel tettük gördülékenyebbé, hogy biztosítani tudjátok pizzátokat a kinézett helyen.
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text textAlign="justify" fontSize="2xl">
                                    Sziasztok,
                                </Text>
                                <Text textAlign="justify" fontSize="2xl">
                                    Ahogy ígértük, mindazok, akik megjelentek a Dripes foglalásukra, de nem tudtak pizzához jutni, esetleg a sort látván nem is próbálkoztak és lemondták az időpontot
                                    (annak ellenére, hogy volt foglalásuk), jön az újrázás!
                                </Text>
                                <Text textAlign="justify" fontSize="2xl">
                                    Szeptember 28-án, szombaton már délben kinyit a zuglói udvar és sülnek a pizzák, hogy mégis lehessen egy olyan nápolyipizza-élményetek, amire vágytatok.
                                </Text>
                            </>
                        )}
                    </VStack>
                </Flex>
            </VStack>
            <VStack>
                {!isLoggedIn ? (
                    <Flex
                        padding="20px 20px"
                        alignItems="flex-start"
                        direction={isMobile ? "column" : "row"}
                    >
                        <VStack spacing={4} align="stretch">
                            <Text textAlign="justify" fontSize="2xl">1. Nézd ki a szimpatikus pizzériát!</Text>
                            <Text textAlign="justify" fontSize="2xl">2. Az adatlapján ellenőrizd, hogy lehet-e foglalni hozzájuk!</Text>
                            <Text textAlign="justify" fontSize="2xl">3. Regisztrálj (ha szükséges) és az ott feltüntetett módon foglald le magadnak/magatoknak az ünnepi pizzád!</Text>
                            <Text textAlign="justify" fontSize="2xl">4. Pontosan jelenj meg a foglalt időpontodra, mert kedvenc pizzériád számol veled!</Text>
                            <Text textAlign="justify" fontSize="2xl" marginTop="8">
                                Ci vediamo!
                            </Text>
                        </VStack>
                    </Flex>
                ) : (
                    <Flex
                        padding="20px 20px"
                        alignItems="flex-start"
                        direction={isMobile ? "column" : "row"}
                    >
                        <VStack spacing={4} align="stretch">
                            <Text textAlign="justify" fontSize="2xl">14:00-22:00-ig, jócskán megnyújtott időben érkezhettek KÓDOTOKKAL vagy felhasználónevetekkel. Fontos, hogy a foglalásodnak
                                megfelelő számú pizza illet meg, vagyis
                                az egyfős (1) foglalásra nem tudnak kiadni 6 darab pizzát.</Text>
                            <Text textAlign="justify" fontSize="2xl">A gördülékeny kiszolgálás érdekében átalakítottuk az oldalt: a ‘foglalásom’ menüpont alatt kattintsatok, hogy mikor érkeznétek, ott
                                látszódni fog, hogy az adott idősáv mennyire sűrű. Ezzel visszajelzést adtok a Dripnek és a többi embernek is. Konkrét időpontfoglalás nincs, 10 órán keresztül nyitva
                                lesznek.
                            </Text>
                            <Text textAlign="justify" fontSize="2xl" >A 3 NPÉ2024 ráadás pizza pedig:</Text>
                            <Text textAlign="justify" fontSize="2xl" >Margherita 1000 forint</Text>
                            <Text textAlign="justify" fontSize="2xl" >Cotto (sonkás) 1500 forint</Text>
                            <Text textAlign="justify" fontSize="2xl" >Picante (pikáns olasz szalámis) 1500 forint</Text>
                            <Text textAlign="justify" fontSize="2xl" >Reméljük találkozunk!</Text>

                        </VStack>
                    </Flex>
                )}
            </VStack>
            <Box textAlign="right" paddingRight="20px" marginTop="4">
                <Text fontSize="xl">
                    Megtaláltok a{' '}
                    <Link
                        href="https://www.facebook.com/profile.php?id=61550673554463"
                        isExternal
                        color="blue.800"
                        textDecoration="underline"
                    >
                        Facebookon
                    </Link>
                </Text>
            </Box>
        </Box>
    );
};

export default IndexPage;
