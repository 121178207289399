import React from 'react';
import { ChakraProvider, Box, Container, Heading, Text } from '@chakra-ui/react';
import { Route, Routes, Navigate } from 'react-router-dom';
import theme from './theme';
import Fonts from './fonts';
import Login from './components/Login';
import Register from './components/Register';
import TermsAndConditions from './components/TermsAndConditions';
import IndexPage from './components/IndexPage';
import Restaurants from './components/Restaurants';
import RestaurantDetail from './components/RestaurantDetail';
import RestaurantForm from './components/RestaurantForm';
import MyReservations from './components/MyReservations';
import UserSearch from './components/User';
import NavBar from './components/NavBar';
import CookiePolicy from './components/CookiePolicy';
import Footer from './components/Footer';
import WhatIsThis from './components/WhatIsThis';
import DripReservation from './components/DripReservation';
import { AuthProvider, useAuth } from './contexts/AuthContext';

const UnderConstruction = () => (
  <Box textAlign="center" py={10}>
    <Heading as="h1" size="2xl">Karbantartás alatt</Heading>
    <Text mt={4}>Dolgozunk az oldal fejlesztésén. Nézz vissza késöbb!</Text>
  </Box>
);

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children }) => {
  const { isLoggedIn, userRoles } = useAuth();
  return isLoggedIn && userRoles.includes('page_admin') ? children : <Navigate to="/" />;
};

const AdminOrRestaurantAdminRoute = ({ children }) => {
  const { isLoggedIn, userRoles } = useAuth();
  return isLoggedIn && (userRoles.includes('page_admin') || userRoles.includes('restaurant_admin')) ? children : <Navigate to="/" />;
};

function App() {
    if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
        return (
            <ChakraProvider theme={theme}>
                <Fonts />
                <UnderConstruction />
            </ChakraProvider>
        );
    }

    return (
        <ChakraProvider theme={theme}>
            <Fonts />
            <AuthProvider>
                <Box minHeight="100vh" display="flex" flexDirection="column">
                    <Box variant="navbar">
                        <NavBar/>
                    </Box>
                    <Container maxW="container.lg" p={4} flex="1">
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            {/*<Route path="/register" element={<Register/>}/>*/}
                            <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                            <Route path="/" element={<IndexPage/>}/>
                            <Route path="/what-is-this" element={<WhatIsThis/>}/>
                            {/* Only visible for page_admins */}
                            <Route path="/restaurants" element={
                                <AdminRoute>
                                    <Restaurants/>
                                </AdminRoute>
                            }/>
                            <Route path="/restaurants/:id" element={
                                <AdminRoute>
                                    <RestaurantDetail/>
                                </AdminRoute>
                            }/>
                            <Route path="/restaurants/new" element={
                                <AdminRoute>
                                    <RestaurantForm/>
                                </AdminRoute>
                            }/>
                            <Route path="/restaurants/edit/:id" element={
                                <AdminRoute>
                                    <RestaurantForm/>
                                </AdminRoute>
                            }/>
                            <Route path="/user-search" element={
                                <AdminRoute>
                                    <UserSearch />
                                </AdminRoute>
                            }/>
                            {/* End of page_admin only routes */}
                            <Route path="/my-reservations" element={
                                <ProtectedRoute>
                                    <MyReservations />
                                </ProtectedRoute>
                            }/>
                            <Route path="/reservations" element={
                                <AdminOrRestaurantAdminRoute>
                                    <MyReservations />
                                </AdminOrRestaurantAdminRoute>
                            }/>
                            <Route path="/drip-reservation" element={
                                <ProtectedRoute>
                                    <DripReservation />
                                </ProtectedRoute>
                            }/>
                            <Route path="/cookie-policy" element={<CookiePolicy />}/>
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                    </Container>
                    <Footer />
                </Box>
            </AuthProvider>
        </ChakraProvider>
    );
}

export default App;

